import React from "react";
import { Link } from "react-router-dom";

export default {
  locale: "ru",
  messages: {
    title_index: "Radiogram",
    title_description: "сервис подкастов",

    sitename: "Radiogram",
    sitename_helper: "слушайте телеграм-каналы",
    meta_sitename: "Radiogram",
    disclaimer: "Подкасты из телеграма",
    write_to_whatsapp: "Напишите нам в WhatsApp",

    bottom_menu_playlist: "Плейлист",
    bottom_menu_channels: "Каналы",
    bottom_menu_account: "Аккаунт",

    channels: "Каналы",
    playlist: "Плейлист",
    playlist_added: "Добавлено в подписки",
    playlist_removed: "Удалено из подписок",

    account: "Аккаунт",

    top_title: "Главное",
    translation_title: "Трансляция",
    translation_telegram: "Трансляция в Телеграм",
    translation_time: "LIVE",

    edit_region: "Изменить регион",
    region_category: "Новости вашего региона:",
    
    favorites: "Ваши подписки",
    favorites_simple: "Подписка",

    favorites_new_casts: "У вас есть новые выпуски для прослушивания.",
    favorites_all: "Все",
    favorites_add: "Подписаться",
    favorites_edit: "Отписаться",
    favorites_added: "Вы подписались на новые выпуски",
    favorites_removed: "Вы отписались от новых выпусков",

    to_radiogram: "В Radiogram",
    last_messages: "Последние посты",
    messages: "Посты",

    know_more: "Узнать больше",
    charity_and_eco: "Польза для экологии",

    my_playlist: "Плейлист",
    account_settings: "Настройки",
    account_player: "Слушать",
    account_channels: "Каналы",

    menu_index: "Главная",
    menu_channels: "Каналам",
    menu_adverts: "Реклама",
    menu_about: "О проекте",
    menu_account: "Плейлист",

    your_playlist: "Ваш плейлист",
    channels_favorites: "В плейлисте",
    channels_new: "Все каналы",
    channels_hot: "Важное",
    channels_search: "Поиск по каналам",
    channels_search_input: "Введите название",

    stats: "Статистика",

    stats_sessionsCount: "Слушатели",
    stats_statsCount: "Прослушивания",
    stats_coverage: "Охват",
    stats_ERR: "ERR, %",

    send_request: "Отправить заявку",
    write_us: "Написать",
    playlist_add: "Добавить каналы",
    playlist_play: "Слушать",
    // playlist_edit: "Выбрать каналы",
    playlist_edit: "Настроить",
    playlist_create: "Создать",
    playlist_channels: "Каналы",
    playlist_done: "На главную",
    modal_close: "Закрыть",

    ai_reading: "Читает ИИ",
    ai_reading_extended: "Посты читает голос, созданный с помощью нейросети и ИИ Radiogram.",
    active_listeners: "Слушатели: {value}",
    partner_share: "Поделиться",
    partner_player: "Слушать",
    partner_playlist: "Плейлист",
    partner_playlist_already: "В плейлисте",

    request_channel_text: "Хотите подключить ваш канал к Radiogram?",
    request_channel_button: "Подключить канал",

    login: "Аккаунт",
    auth: "Войдите, чтобы продолжить",
    // auth_to_continue: "Введите e-mail, чтобы продолжить",
    // auth_title: "Войти или создать аккаунт",
    email: "Ваш e-mail",
    code: "Введите код",
    auth_title: "Вход",
    auth_phone: "Ваш телефон",
    auth_email: "Войдите, чтобы продолжить",
    auth_is_simple: "Войдите, чтобы продолжить. Это просто.",
    auth_code: "Код из письма",
    auth_requestCode: "Получить код",
    auth_reRequestCode: "Получить код",
    auth_validateCode: "Войти",
    auth_wrongCode: "Неверный код",
    auth_wrongLogin: "Неверный логин",
    auth_codeSent: "Вам отправлено письмо с кодом.",
    auth_reSend: "Новый код через {seconds} сек.",
    auth_required: "Необходимо авторизоваться",
    telegram_codeSend: `Отправьте этот код в наш телеграм-бот`,
    telegram_codeSent: `Откройте наш <a href="tg://resolve?domain={bot}&text=/code {code}">телеграм-бот</a> и отправьте в него код <pre>/code {code}</pre>`,
    telegram_bot_open: `Отправить`,
    telegram_code_copied: `Код скопирован`,

    auth_offer_required: (
      <>
        Я принимаю{" "}
        <a href="/offer" className="text-black" target="_blank">
          пользовательское соглашение
        </a>
      </>
    ),

    premium: "Премиум",
    premium_cancel_confirm: "Удалить подписку?",
    premium_expired_at: "Оформлен до {date}",

    faq: "FAQ",
    url_copied: "Ссылка скопирована",
    settings: "Ваши данные",
    settings_location: "Ваш город",
    settings_name: "Ваше имя",
    settings_no_name: "Имя и фамилия не указаны",
    settings_name_edit: "Изменить имя и фамилию",
    settings_phone: "Номер телефона",
    settings_phone_edit: "Изменить номер телефона",
    settings_new_phone: "Введите новый номер",
    settings_email: "Email",
    settings_email_edit: "Изменить e-mail",
    settings_new_email: "Введите новый e-mail",

    privacy: "Политика безопасности",
    agreement: "Пользовательское соглашение",
    offer: "Пользовательское соглашение",

    page_not_found: "Страница не найдена",
    about: "О нас",

    days: "{value} д",
    hours: "{value} ч",
    minutes: "{value} мин",
    seconds: "{value} сек.",
    ago: "назад",
    right_now: "только что",
    header_top_text: "Слушайте прямо в Телеграм",
    subscribe_in_telegram: "Открыть",
    listen_in_telegram: "Телеграм",
    listen_in_youtube: "Скоро",
    listen_in_apple: "Скоро",
    listen_in_vk: "Скоро",
    listen_in_yandex: "Скоро",

    settings: "Ваши данные",

    feedback: "Отзывы",
    feedback_all: "Все отзывы",
    feedback_title: "Отзывы клиентов",
    feedback_description: "Обратная связь: отзывы клиентов и покупателей",

    feedback_anonymous: "Аноним",
    feedback_form: "Поставьте оценку и получите промокод на следующий заказ 💌",
    feedback_score: "Оценка",
    feedback_message: "Текст отзыва",
    feedback_add: "Отправить",
    feedback_success: "Спасибо за отзыв! Мы отправим вам СМС с промокодом",
    feedback_error: "Произошла ошибка. Попробуйте позже, или напишите нам в WhatsApp",

    account_index: "Войти",
    partners_index: "Партнеры",
    make_eco: "Сохранить лес за {value} ₽",
    to_partners: "Телеграм-каналам",
    to_marketing: "Рекламодателям",
    become_partners: "Стать партнером",
    connect_free: "Подключить бесплатно",
    connect_partner: "Заявка",
    connect_success: "Заявка отправлена. Мы свяжемся с вами в ближайшее время.",
    connect_error: "Проверьте все поля",
    partner_name: "Компания",
    partner_person: "Имя",
    partner_phone: "Телефон",
    partner_email: "E-mail",
    partner_www: "Сайт",
    partner_send: "Подключить Tree Pay бесплатно",

    more_channels: "Рекомендации",
    more_channels_text: "Добавьте каналы в ваш плейлист.",
    all_channels: "Все каналы",

    soon: "Скоро",

    time_limit: "Время для оплаты",
    payment_failure: "Что-то пошло не так 😱",
    payment_failure_text: "Ваш платеж не прошел. Проверьте данные для оплаты или баланс карты.",
    payment_failure_button: "Вернуться и попробовать еще раз",
    payment_checking: "Ваш платеж проверяется",
    payment_success: "Спасибо",
    payment_success_text: "Вы оформили подписку премиум до {date}",
    payment_success_button: "Посмотреть мой вклад в экологию",

    empty_player_list: "Нет сообщений",

    collapse_messages: "Свернуть",
    show_all_messages: "Показать все посты ({count})",

    load_more: "Предыдущие",
    show_more: "Показать всё",

    dateTimeFormat: "DD.MM.YYYY HH:mm",
    dateFormat: "DD.MM.YYYY",
    dateShortFormat: "DD.MM",
    timeShortFormat: "HH:mm",

    day_0: "Вс",
    day_1: "Пн",
    day_2: "Вт",
    day_3: "Ср",
    day_4: "Чт",
    day_5: "Пт",
    day_6: "Сб",

    home: "Домой",
    features: "Особенности",
    new: "Новинки",
    blog: "Блог",

    share: "Ссылка",
    share_title: "Поделитесь картинкой в соцсети",
    share_title_: "Скопируйте эту картинку и поделитесь ей в соцсети",
    share_text: "Сохраните эту картинку и поделитесь ей в соцсети",
    share_save: "Скачать картинку",

    logout: "Выйти из аккаунта",

    save: "Cохранить",
    edit: "Изменить",
    free: "Бесплатно",
    refresh: "Обновить",
    reset: "Сбросить",
    apply: "Применить",
    total: "Итого",
    loading: "Идет загрузка...",
    more: "Ещё",
    main_title: "Плейлисты",

    date: "Дата",
    time: "Время",
    important: "Важно",
    casts_archive: "Архив выпусков",
    casts_all: "Все выпуски",
    cast_read: "Читать",
    cast_posts: "Главные посты",
    cast_trailer: "Трейлер",
    casts_read_version: "Версия для чтения",
    into_cast: "в подкасте",

    specials_casts: "Актуальное",

    general: "Топ",
    search: "Поиск",
    today: "Сегодня",
    yesterday: "Вчера",
    before_yesterday: "Позавчера",
    now_listening: " Сейчас слушают",

    contacts: "Контакты",
    support_contacts: "Служба поддержки",

    show_details: "Узнать больше",

    name: "Имя",
    surname: "Фамилия",
  },
};
