import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "react-redux-multilingual";
import Col3Container from "components/standart/col3Container";
import { fileUrl } from "reducers/api";
import FavoritesCasts from "./account/casts";
import Auth from "./auth/index";

const FavoritesPage = (props) => {
  const translate = useTranslate();

  const { env } = useSelector((state) => state.frontend);
  const { session } = useSelector((state) => state.user);

  const { meta = {} } = env;
  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
    fileId,
  } = meta.favorites || {};

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:keywords" content={keywords} />
      </Helmet>
      {fileId ? (
        <Helmet>
          <meta property="og:image" content={fileUrl(fileId, "image.jpg")} />
          <meta property="image" content={fileUrl(fileId, "image.jpg")} />
        </Helmet>
      ) : null}

      <section className="p-0">
        <div className="container">
          <Col3Container>
            {session.account ? (
              <FavoritesCasts />
            ) : (
              <div className="text-center">
                <div className="large-header pb-2">{translate("auth_title")}</div>
                <Auth />
              </div>
            )}
          </Col3Container>
        </div>
      </section>
    </>
  );
};

export default connect(null, {})(FavoritesPage);
