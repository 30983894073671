import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { useTranslate } from "react-redux-multilingual";
import { scrollTop } from "components/standart";
import { setNavi } from "actions";
import { BlockSection, IntroSection } from "components/standart/LandingSections";
import Col3Container from "components/standart/col3Container";
import { ContactsSection } from "components/standart/ContactsSection";
import CountryCategoriesModal from "components/standart/country-categories-modal";
import CastsFavoriteButton from "components/standart/cast-favorite-button";
import { fileUrl, frontendApi } from "reducers/api";
import { InfoBlocks } from "./infoblocks";
import nl2br from "react-nl2br";
const { api: apiFrontend } = frontendApi.actions;

const OnboardPage = (props) => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const { env } = useSelector((state) => state.frontend);
  const [categories, setCategories] = useState([]);
  const [regionCategories, setRegionCategories] = useState([]);

  const { meta = {}, settings = {} } = env;

  const loadCategories = () => {
    props.getCastsCategories(
      {},
      {
        onSuccess(body) {
          setCategories(body);
        },
      }
    );
    /*
    props.getRegionCategories(
      { query: { regionOnly: true } },
      {
        onSuccess(body) {
          setRegionCategories(body);
        },
      }
    );
    */
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const {
    title = translate("title_index"),
    description = translate("title_description"),
    keywords = "",
    fileId,
  } = meta.onboard || {};
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="og:keywords" content={keywords} />
      </Helmet>
      {fileId ? (
        <Helmet>
          <meta property="og:image" content={fileUrl(fileId, "image.jpg")} />
          <meta property="image" content={fileUrl(fileId, "image.jpg")} />
        </Helmet>
      ) : null}

      <section className="p-0">
        <div className="container">
          <Col3Container>
            <div className="text-center">
              <div className="text-center">
                <div className="large-header pt-2">{settings.onboardBlock.title}</div>
                <div className="pt-2 pb-4">{nl2br(settings.onboardBlock.content)}</div>
              </div>
              <div className="pb-3">
                {/* <div className="row"> */}
                {_.map(categories, (category) => {
                  return !category.countryId ? (
                    <span className="mr-3" key={category._id}>
                      <CastsFavoriteButton
                        categoryId={category._id}
                        style={{ padding: "0 6px" }}
                        className="btn btn-outline grey-btn standart-header mb-3"
                        isButton={true}
                        showText={true}
                        text={category.name}
                        iconClassName={"fas"}
                      />
                    </span>
                  ) : null;
                })}

                <div className="my-3">{translate("region_category")}</div>

                <div className="-d-flex">
                  {_.map(categories, (category) => {
                    return category.countryId ? (
                      <div className="mr-3" key={category._id}>
                        <CastsFavoriteButton
                          categoryId={category._id}
                          style={{ padding: "0 6px" }}
                          className="btn btn-outline grey-btn standart-header mb-3"
                          isButton={true}
                          showText={true}
                          text={category.name}
                          iconClassName={"fas"}
                        />
                      </div>
                    ) : null;
                  })}

                  <div className="mb-4">
                    <CountryCategoriesModal successHandler={() => loadCategories()} />
                  </div>
                </div>

                {/* 
              {_.map(regionCategories, (category) => {
                return category.countryId ? (
                  <span className="mr-3" key={category._id}>
                    <CastsFavoriteButton
                      categoryId={category._id}
                      style={{ padding: "0 6px" }}
                      className="btn btn-outline grey-btn standart-header mb-3"
                      isButton={true}
                      showText={true}
                      text={category.name}
                      iconClassName={"fas"}
                    />
                  </span>
                ) : null;
              })} */}
                <div className="text-center">
                  <button
                    className="btn btn-solid standart-header mb-4"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <i className="fa fa-home mr-2" />
                    {settings.onboardBlock.button}
                  </button>
                </div>
              </div>

              <InfoBlocks showLiveStream={false} showJoinChannel={false} />

              {/* </div> */}
            </div>
          </Col3Container>
        </div>
      </section>
    </>
  );
};

export default connect(null, {
  getCastsCategories: apiFrontend.get("/casts/categories"),
  getRegionCategories: apiFrontend.get("/casts/categories"),
  setNavi,
})(OnboardPage);
